import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {Message, Breadcrumb, Card, Grid, Space, Button, Statistic, DatePicker} from "@arco-design/web-react";
import {IconUser, IconUserAdd, IconUserGroup, IconCustomerService, IconSearch, IconUpload, IconDownload, IconSync} from "@arco-design/web-react/icon";
import {VChart} from "@visactor/react-vchart";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useOutletContext} from "react-router-dom";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Loading from "../../../../../nowui-common/component/loading";

import Image0 from "./image/0.png";
import Image1 from "./image/1.png";

import "./index.css";

const DashboardIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, []);

    return (
        <React.Fragment>
            <div className="dashboard_container">
                <div className="dashboard_header">
                    <div className="dashboard_header_button"></div>
                    <div className="dashboard_header_title"></div>
                    <div className="dashboard_header_time"></div>
                </div>
                <div className="dashboard_content">
                    <div className="dashboard_content_col_0">
                        <div className="dashboard_content_col_item_title"></div>
                        <div className="dashboard_content_col_item_0">
                            <div className="dashboard_content_col_item_0_4"></div>
                            <div className="dashboard_content_col_item_0_5"></div>
                            <div className="dashboard_content_col_item_0_6"></div>
                            <div className="dashboard_content_col_item_0_7"></div>
                            <div className="dashboard_content_col_item_0_0"></div>
                            <div className="dashboard_content_col_item_0_1"></div>
                            <div className="dashboard_content_col_item_0_2"></div>
                            <div className="dashboard_content_col_item_0_3"></div>
                            <div className="dashboard_content_col_item_0_main">
                                <div className="dashboard_content_col_item_0_main_title">
                                    <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                </div>
                                <div id="echarts0" style={{width: 400, height: 345}}></div>
                            </div>
                        </div>
                        <div className="dashboard_empty"></div>
                        <div className="dashboard_content_col_item_title"></div>
                        <div className="dashboard_content_col_item_1">
                            <div className="dashboard_content_col_item_0_4"></div>
                            <div className="dashboard_content_col_item_0_5"></div>
                            <div className="dashboard_content_col_item_0_6"></div>
                            <div className="dashboard_content_col_item_0_7"></div>
                            <div className="dashboard_content_col_item_0_0"></div>
                            <div className="dashboard_content_col_item_0_1"></div>
                            <div className="dashboard_content_col_item_0_2"></div>
                            <div className="dashboard_content_col_item_0_3"></div>
                            <div className="dashboard_content_col_item_0_main">
                                <div className="dashboard_content_col_item_0_main_title">
                                    <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                </div>
                                <div id="echarts1" style={{width: 400, height: 380}}></div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_content_col_1">
                        <div className="dashboard_content_col_item_3">
                            <div className="dashboard_content_col_item_3_0"></div>
                            <div className="dashboard_content_col_item_3_1">
                                <div className="dashboard_content_col_item_3_1_1"></div>
                            </div>
                            <div className="dashboard_content_col_item_3_2">
                                <div className="dashboard_content_col_item_3_2_0">
                                    <div className="dashboard_content_col_item_3_2_0_0"></div>
                                    <div className="dashboard_content_col_item_3_2_0_1">
                                        <span className="dashboard_content_col_item_3_2_0_1_0"></span>
                                    </div>
                                </div>
                                <div className="dashboard_content_col_item_3_2_0">
                                    <div className="dashboard_content_col_item_3_2_0_0"></div>
                                    <div className="dashboard_content_col_item_3_2_0_1">
                                        <span className="dashboard_content_col_item_3_2_0_1_0"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard_empty"></div>
                        <div className="dashboard_content_col_item_4">
                            <div className="dashboard_content_col_item_4_0">
                                <div className="dashboard_content_col_item_title"></div>
                                <div className="dashboard_content_col_item_0_4"></div>
                                <div className="dashboard_content_col_item_0_5"></div>
                                <div className="dashboard_content_col_item_0_6"></div>
                                <div className="dashboard_content_col_item_0_7"></div>
                                <div className="dashboard_content_col_item_0_0"></div>
                                <div className="dashboard_content_col_item_0_1"></div>
                                <div className="dashboard_content_col_item_0_2"></div>
                                <div className="dashboard_content_col_item_0_3"></div>
                                <div className="dashboard_content_col_item_0_main" style={{marginRight: "30px"}}>
                                    <div className="dashboard_content_col_item_0_main_title">
                                        <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                    </div>
                                    <div id="echarts6" style={{width: 160, height: 160}}></div>
                                    <div className="dashboard_content_col_item_0_main_title" style={{marginTop: "15px"}}></div>
                                    <div id="echarts7" style={{width: 160, height: 160}}></div>
                                </div>
                            </div>
                            <div style={{flex: 1, marginLeft: "20px"}}>
                                <div className="dashboard_content_col_item_4_1">
                                    <div className="dashboard_content_col_item_0_4"></div>
                                    <div className="dashboard_content_col_item_0_5"></div>
                                    <div className="dashboard_content_col_item_0_6"></div>
                                    <div className="dashboard_content_col_item_0_7"></div>
                                    <div className="dashboard_content_col_item_0_0"></div>
                                    <div className="dashboard_content_col_item_0_1"></div>
                                    <div className="dashboard_content_col_item_0_2"></div>
                                    <div className="dashboard_content_col_item_0_3"></div>
                                    <div className="dashboard_content_col_item_0_main">
                                        <div className="dashboard_content_col_item_0_main_title">
                                            <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                        </div>
                                        <div id="echarts2" style={{width: 400, height: 190}}></div>
                                    </div>
                                </div>
                                <div className="dashboard_content_col_item_4_2">
                                    <div className="dashboard_content_col_item_0_4"></div>
                                    <div className="dashboard_content_col_item_0_5"></div>
                                    <div className="dashboard_content_col_item_0_6"></div>
                                    <div className="dashboard_content_col_item_0_7"></div>
                                    <div className="dashboard_content_col_item_0_0"></div>
                                    <div className="dashboard_content_col_item_0_1"></div>
                                    <div className="dashboard_content_col_item_0_2"></div>
                                    <div className="dashboard_content_col_item_0_3"></div>
                                    <div className="dashboard_content_col_item_0_main">
                                        <div className="dashboard_content_col_item_0_main_title">
                                            <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                        </div>
                                        <div id="echarts3" style={{width: 400, height: 190}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_content_col_2">
                        <div className="dashboard_content_col_item_title"></div>
                        <div className="dashboard_content_col_item_7">
                            <div className="dashboard_content_col_item_7_0">
                                <div className="dashboard_content_col_item_0_4"></div>
                                <div className="dashboard_content_col_item_0_5"></div>
                                <div className="dashboard_content_col_item_0_6"></div>
                                <div className="dashboard_content_col_item_0_7"></div>
                                <div className="dashboard_content_col_item_0_0"></div>
                                <div className="dashboard_content_col_item_0_1"></div>
                                <div className="dashboard_content_col_item_0_2"></div>
                                <div className="dashboard_content_col_item_0_3"></div>
                                <div className="dashboard_content_col_item_0_main">
                                    <div className="dashboard_content_col_item_0_main_title"></div>
                                    <div id="echarts5" style={{width: 200, height: 320}}></div>
                                </div>
                            </div>
                            <div style={{flex: 1, marginLeft: "20px"}}>
                                <div className="dashboard_content_col_item_7_1">
                                    <div className="dashboard_content_col_item_0_4"></div>
                                    <div className="dashboard_content_col_item_0_5"></div>
                                    <div className="dashboard_content_col_item_0_6"></div>
                                    <div className="dashboard_content_col_item_0_7"></div>
                                    <div className="dashboard_content_col_item_0_0"></div>
                                    <div className="dashboard_content_col_item_0_1"></div>
                                    <div className="dashboard_content_col_item_0_2"></div>
                                    <div className="dashboard_content_col_item_0_3"></div>
                                    <div className="dashboard_content_col_item_0_main">
                                        <div className="dashboard_content_col_item_0_main_title">
                                            <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                        </div>
                                        <div className="dashboard_content_col_item_7_2_0">
                                            <span className="dashboard_content_col_item_3_2_0_1_0"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard_content_col_item_7_2">
                                    <div className="dashboard_content_col_item_0_4"></div>
                                    <div className="dashboard_content_col_item_0_5"></div>
                                    <div className="dashboard_content_col_item_0_6"></div>
                                    <div className="dashboard_content_col_item_0_7"></div>
                                    <div className="dashboard_content_col_item_0_0"></div>
                                    <div className="dashboard_content_col_item_0_1"></div>
                                    <div className="dashboard_content_col_item_0_2"></div>
                                    <div className="dashboard_content_col_item_0_3"></div>
                                    <div className="dashboard_content_col_item_0_main">
                                        <div className="dashboard_content_col_item_0_main_title">
                                            <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                        </div>
                                        <div className="dashboard_content_col_item_7_2_0">
                                            <span className="dashboard_content_col_item_3_2_0_1_0"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard_empty"></div>
                        <div className="dashboard_content_col_item_title"></div>
                        <div className="dashboard_content_col_item_8">
                            <div className="dashboard_content_col_item_0_4"></div>
                            <div className="dashboard_content_col_item_0_5"></div>
                            <div className="dashboard_content_col_item_0_6"></div>
                            <div className="dashboard_content_col_item_0_7"></div>
                            <div className="dashboard_content_col_item_0_0"></div>
                            <div className="dashboard_content_col_item_0_1"></div>
                            <div className="dashboard_content_col_item_0_2"></div>
                            <div className="dashboard_content_col_item_0_3"></div>
                            <div className="dashboard_content_col_item_0_main">
                                <div className="dashboard_content_col_item_0_main_title">
                                    <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                </div>
                                <div id="echarts4" style={{width: 350, height: 190}}></div>
                            </div>
                        </div>
                        <div className="dashboard_content_col_item_9">
                            <div className="dashboard_content_col_item_0_4"></div>
                            <div className="dashboard_content_col_item_0_5"></div>
                            <div className="dashboard_content_col_item_0_6"></div>
                            <div className="dashboard_content_col_item_0_7"></div>
                            <div className="dashboard_content_col_item_0_0"></div>
                            <div className="dashboard_content_col_item_0_1"></div>
                            <div className="dashboard_content_col_item_0_2"></div>
                            <div className="dashboard_content_col_item_0_3"></div>
                            <div className="dashboard_content_col_item_0_main_2">
                                <div style={{flex: 1}}>
                                    <div className="dashboard_content_col_item_0_main_title">
                                        <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                    </div>
                                    <div id="echarts8" style={{width: 150, height: 150}}></div>
                                </div>
                                <div style={{flex: 1}}>
                                    <div className="dashboard_content_col_item_0_main_title">
                                        <img src={Image1} className="dashboard_content_col_item_0_main_title_image" alt="" />
                                    </div>
                                    <div id="echarts9" style={{width: 150, height: 150}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoad ? <Loading /> : null}
        </React.Fragment>
    );
};

DashboardIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(DashboardIndex);
