import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {Message, Breadcrumb, Card, Grid, Form, Button, Space, InputNumber, Table} from "@arco-design/web-react";
import {IconSave, IconPlusCircle, IconDelete} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Loading from "../../../../../nowui-common/component/loading";
import File from "../../../../../nowui-common/component/file";

const ConfigIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [configId, setConfigId] = useState("");
    const [configVideoList, setConfigVideoList] = useState([]);
    const [selectedIdList, setSelectedIdList] = useState([]);

    useEffect(() => {
        handleLoadConfig();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/config/index");
        setBreadcrumbList(breadcrumbList);
    }, [localStorage.getItem("locale")]);

    const handleLoadConfig = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/config/backend/website/v1/item",
            controller: controller,
            data: {
                configId: props.configId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                setConfigId(response.data.configId);
                setConfigVideoList(response.data.configVideoList);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleUpdateConfig = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.configId = configId;
                data.configVideoList = configVideoList;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/config/backend/website/v1/update",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    return (
        <React.Fragment>
            <Scrollbars
                autoHeight={autoHeight}
                autoHeightMax={main.height - 300}
                renderView={({style, ...props}) => {
                    return <div className="scrollbar" style={style} {...props} />;
                }}
            >
                <Breadcrumb className="breadcrumb">
                    {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                        return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                    })}
                    {props.breadcrumb ? <Breadcrumb.Item key={props.breadcrumb.name}>{props.breadcrumb.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
                <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                <Form form={form} autoComplete="off" layout="vertical">
                    <Card className="card" title={outletContext.intl.formatMessage({id: "global.basicInformation"})} bordered={false}>
                        <div className="card_table_action">
                            <Grid.Row gutter={20}>
                                <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                    <Form.Item
                                        label={outletContext.intl.formatMessage({id: "config.checkPoint"})}
                                        field="checkPoint"
                                        initialValue={0}
                                        rules={[
                                            {
                                                type: "number",
                                                min: 0,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                            allowClear
                                            onPressEnter={() => {
                                                handleUpdateConfig();
                                            }}
                                        />
                                    </Form.Item>
                                </Grid.Col>
                                <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                    <Form.Item
                                        label={outletContext.intl.formatMessage({id: "config.rectifyPoint"})}
                                        field="rectifyPoint"
                                        initialValue={0}
                                        rules={[
                                            {
                                                type: "number",
                                                min: 0,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                            allowClear
                                            onPressEnter={() => {
                                                handleUpdateConfig();
                                            }}
                                        />
                                    </Form.Item>
                                </Grid.Col>
                                <Grid.Col xs={24} sm={24} md={24} lg={24}>
                                    {main.permissionList.indexOf(Util.md5("/config/backend/website/v1/update")) > -1 ? (
                                        <Button
                                            type="primary"
                                            icon={<IconSave />}
                                            loading={isLoad}
                                            onClick={() => {
                                                handleUpdateConfig();
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.update"})}
                                        </Button>
                                    ) : null}
                                </Grid.Col>
                            </Grid.Row>
                        </div>
                    </Card>
                </Form>
            </Scrollbars>
            {isLoad ? <Loading /> : null}
        </React.Fragment>
    );
};

ConfigIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(ConfigIndex);
