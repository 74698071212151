import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Message, Card, Form, Button, Input, Checkbox} from "@arco-design/web-react";
import {Scrollbars} from "react-custom-scrollbars-2";
import {injectIntl} from "react-intl";

import Util from "../../../../../nowui-common/js/util";

import Help from "../../../../../nowui-common/component/help";
import Error from "../../../../../nowui-common/component/error";
import Loading from "../../../../../nowui-common/component/loading";

const LoginIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const dispatch = useDispatch();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);

    useEffect(() => {
        let locale = localStorage.getItem("locale");
        if (locale) {
            dispatch({
                type: "main",
                data: {
                    locale: locale,
                },
            });
        }

        let theme = localStorage.getItem("theme");
        if (theme) {
            dispatch({
                type: "main",
                data: {
                    theme: theme,
                },
            });
        }

        return () => {
            controller.abort();
        };
    }, []);

    const handleLogin = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: props.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.userPassword = Util.md5(data.userPassword);

                Util.request(
                    {
                        intl: props.intl,
                    },
                    {
                        url: process.env.API_URL + "/user/backend/website/v1/login",
                        controller: controller,
                        data: data,
                        success: (response) => {
                            Message.success({
                                id: "loading",
                                content: response.message,
                            });

                            localStorage.setItem("authorization", response.data.authorization);

                            request.current = false;
                            setIsLoad(false);

                            setTimeout(() => {
                                Util.redirectTo(props, {
                                    url: "/",
                                });
                            }, 300);
                        },
                        error: (response) => {
                            Message.error({
                                id: "loading",
                                content: response.message,
                            });

                            setErrorTitle(response.message);
                            setErrorList(response.error);

                            request.current = false;
                            setIsLoad(false);
                        },
                        complete: () => {
                            request.current = false;
                            setIsLoad(false);
                        },
                    }
                );
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    return (
        <React.Fragment>
            <Scrollbars
                renderView={({style, ...props}) => {
                    return <div className="login" style={style} {...props} />;
                }}
            >
                <Help
                    outletContext={{
                        intl: props.intl,
                    }}
                    className="login_help"
                    logoutUrl={process.env.API_URL + "/edu/form/user/backend/website/v1/logout"}
                    changePasswordUrl={process.env.API_URL + "/edu/form/user/backend/website/v1/change/password"}
                    isDrawer={false}
                    setIsDrawer={() => {}}
                    isLogin={false}
                />
                <div className="login_content">
                    <Card className="login_card" bordered={false}>
                        <div className="login_title">{document.title}</div>
                        <Form form={form} autoComplete="off" layout="vertical">
                            <Form.Item
                                label={props.intl.formatMessage({id: "user.userAccount"})}
                                field="userAccount"
                                initialValue={"15900672218"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        type: "string",
                                        minLength: 0,
                                        maxLength: 50,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={props.intl.formatMessage({id: "global.pleaseEnter"})}
                                    allowClear
                                    onPressEnter={() => {
                                        handleLogin();
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={props.intl.formatMessage({id: "user.userPassword"})}
                                field="userPassword"
                                initialValue={"12345678"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        type: "string",
                                        minLength: 0,
                                        maxLength: 50,
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder={props.intl.formatMessage({id: "global.pleaseEnter"})}
                                    allowClear
                                    onPressEnter={() => {
                                        handleLogin();
                                    }}
                                />
                            </Form.Item>
                            <Form.Item field="remember" initialValue={false}>
                                <Checkbox>7天内自动登录</Checkbox>
                            </Form.Item>
                        </Form>
                        <Button
                            className="login_submit"
                            type="primary"
                            size="large"
                            long
                            loading={isLoad}
                            onClick={() => {
                                handleLogin();
                            }}
                        >
                            {props.intl.formatMessage({id: "global.login"})}
                        </Button>
                        <Error
                            style={{margin: "20px 0px 0px 0px"}}
                            errorTitle={errorTitle}
                            errorList={errorList}
                            setErrorTitle={setErrorTitle}
                            setErrorList={setErrorList}
                        />
                    </Card>
                </div>
                {isLoad ? <Loading /> : null}
            </Scrollbars>
        </React.Fragment>
    );
};

LoginIndex.propTypes = {};

export default Util.withRouter(injectIntl(LoginIndex));
