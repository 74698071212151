import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "./ckeditor";
import axios from "axios";
import PropTypes from "prop-types";

import Util from "../../js/util";

class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then((file) => {
            return new Promise((resolve, reject) => {
                const data = new FormData();
                data.append("file", file);
                axios
                    .post(process.env.API_URL + process.env.UPLOAD_PATH, data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: localStorage.getItem("authorization"),
                        },
                    })
                    .then((response) => {
                        if (response.data.code == 0) {
                            resolve({
                                default: process.env.FILE_URL + response.data.data.filePath,
                            });
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch((response) => {
                        reject("Upload failed");
                    });
            });
        });
    }

    abort() {}
}

const EditorIndex = ({mobile = true, ...props}) => {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <React.Fragment>
            <div className={mobile ? "editor_mobile" : null}>
                <CKEditor
                    editor={DecoupledEditor}
                    config={{
                        StyleConfig: {
                            width: "450px",
                        },
                    }}
                    data={props.value}
                    onReady={(editor) => {
                        editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());

                        editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                            return new UploadAdapter(loader);
                        };
                    }}
                    onError={(error, {willEditorRestart}) => {
                        if (willEditorRestart) {
                            this.editor.ui.view.toolbar.element.remove();
                        }
                    }}
                    onChange={(event, editor) => {
                        props.onChange(editor.getData());
                    }}
                />
            </div>
        </React.Fragment>
    );
};

EditorIndex.propTypes = {};

export default Util.withRouter(EditorIndex);
