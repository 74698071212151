import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {Message, Modal, Breadcrumb, Card, Grid, Form, Space, Button, Input, Select, Switch, Table} from "@arco-design/web-react";
import {IconRefresh, IconClose, IconCloseCircle, IconCheckCircle, IconPlusCircle, IconDelete} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import File from "../../../../../nowui-common/component/file";
import Loading from "../../../../../nowui-common/component/loading";

const RiskDetail = ({...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState({});
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [companyUserList, setCompanyUserList] = useState([]);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [riskItemList, setRiskItemList] = useState([]);

    useEffect(() => {
        let breadcrumb = {};
        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/risk/index");
        if (props.action == "save") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.new"}) + outletContext.intl.formatMessage({id: "risk"}).toLowerCase(),
            };

            handleLoadCompanyList();
        } else if (props.action == "update") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.modify"}) + outletContext.intl.formatMessage({id: "risk"}).toLowerCase(),
            };

            handleLoadRisk();

            handleLoadCompanyList();

            setIsRefresh(true);
        }
        setBreadcrumb(breadcrumb);
        setBreadcrumbList(breadcrumbList.concat([breadcrumb]));

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        return () => {};
    }, [localStorage.getItem("locale")]);

    const handleLoadCompanyList = () => {
        Util.request(outletContext, {
            url: process.env.API_URL + "/company/backend/website/v1/list/select",
            controller: controller,
            data: {},
            success: (response) => {
                setCompanyList(response.data);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);
            },
            complete: () => {},
        });
    };

    const handleLoadCompanyUserList = (companyId) => {
        Util.request(outletContext, {
            url: process.env.API_URL + "/company/user/backend/website/v1/list/select",
            controller: controller,
            data: {
                companyId: companyId,
            },
            success: (response) => {
                setCompanyUserList(response.data);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);
            },
            complete: () => {},
        });
    };

    const handleLoadRisk = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/risk/backend/website/v1/item",
            controller: controller,
            data: {
                riskId: props.riskId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                setRiskItemList(response.data.riskItemList);

                handleLoadCompanyUserList(response.data.companyId);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleSubmitRisk = () => {
        if (props.action == "save") {
            handleSaveRisk();
        } else if (props.action == "update") {
            handleUpdateRisk();
        }
    };

    const handleSaveRisk = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.riskItemList = riskItemList;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/risk/backend/website/v1/save",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    const handleUpdateRisk = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.riskId = props.riskId;
                data.riskItemList = riskItemList;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/risk/backend/website/v1/update",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}
                title={
                    <React.Fragment>
                        <div className="arco-modal-title-text">{breadcrumb.name}</div>
                        <Space>
                            {main.permissionList.indexOf(Util.md5("/risk/backend/website/v1/item")) > -1 && isRefresh ? (
                                <Button
                                    className="arco-modal-title-button"
                                    type="text"
                                    icon={<IconRefresh className="navigation_icon" />}
                                    onClick={() => {
                                        handleLoadRisk();
                                    }}
                                ></Button>
                            ) : null}
                            <Button
                                className="arco-modal-title-button"
                                type="text"
                                icon={<IconClose className="navigation_icon" />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            ></Button>
                        </Space>
                    </React.Fragment>
                }
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                closeIcon={false}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, marginRight: "8px"}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                            {main.permissionList.indexOf(Util.md5("/risk/backend/website/v1/save")) > -1 && props.action === "save" ? (
                                <Button
                                    type="primary"
                                    icon={<IconCheckCircle />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitRisk();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.save"})}
                                </Button>
                            ) : null}
                            {main.permissionList.indexOf(Util.md5("/risk/backend/website/v1/update")) > -1 && props.action === "update" ? (
                                <Button
                                    type="primary"
                                    icon={<IconCheckCircle />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitRisk();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.update"})}
                                </Button>
                            ) : null}
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        style.overflow = isLoad ? "hidden" : "auto";

                        return <div className="scrollbar" style={style} {...props} />;
                    }}
                >
                    <div style={{width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}>
                        <Breadcrumb className="breadcrumb">
                            {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                                return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                            })}
                        </Breadcrumb>
                        <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                        <Form form={form} autoComplete="off" layout="vertical">
                            <Card className="card" title={outletContext.intl.formatMessage({id: "global.basicInformation"})} bordered={false}>
                                <Grid.Row gutter={20}>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "company.companyName"})}
                                            field="companyId"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 32,
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseSelect"})}
                                                allowClear
                                                onChange={(event) => {}}
                                            >
                                                {companyList.map((company, companyIndex) => {
                                                    return (
                                                        <Select.Option key={companyIndex} value={company.companyId}>
                                                            {company.companyName}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "risk.riskName"})}
                                            field="riskName"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitRisk();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "risk.riskImage"})}
                                            field="riskImage"
                                            rules={[
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 200,
                                                },
                                            ]}
                                        >
                                            <File type="image" limit={1} />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "risk.riskStatus"})}
                                            field="riskStatus"
                                            initialValue={true}
                                            rules={[]}
                                            triggerPropName="checked"
                                        >
                                            <Switch
                                                checkedText={outletContext.intl.formatMessage({id: "global.on"})}
                                                uncheckedText={outletContext.intl.formatMessage({id: "global.off"})}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                </Grid.Row>
                            </Card>
                            {props.action == "update" ? (
                                <Card className="card" title={outletContext.intl.formatMessage({id: "risk.riskItem"})} bordered={false}>
                                    <div className="card_table_action">
                                        <Space className="action_detail" wrap>
                                            <Button
                                                icon={<IconPlusCircle />}
                                                onClick={() => {
                                                    riskItemList.push({
                                                        itemId: Util.getId(),
                                                        riskFactor: null,
                                                        riskPost: null,
                                                        accidentType: null,
                                                        precautionaryMeasure: null,
                                                        controlDepartment: null,
                                                        userIdList: [],
                                                    });
                                                    setRiskItemList([...riskItemList]);
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.save"})}
                                            </Button>
                                            <Button
                                                icon={<IconDelete />}
                                                disabled={selectedIdList.length == 0}
                                                onClick={() => {
                                                    for (let i = 0; i < selectedIdList.length; i++) {
                                                        for (let j = 0; j < riskItemList.length; j++) {
                                                            if (selectedIdList[i] == riskItemList[j].id) {
                                                                riskItemList.splice(j, 1);
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    setRiskItemList([...riskItemList]);
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.delete"})}
                                            </Button>
                                        </Space>
                                        <Table
                                            className="table"
                                            columns={[
                                                {
                                                    dataIndex: "riskFactor",
                                                    title: outletContext.intl.formatMessage({id: "risk.riskFactor"}),
                                                    render: (col, record, index) => (
                                                        <React.Fragment>
                                                            <Input
                                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                allowClear
                                                                value={record.riskFactor}
                                                                onChange={(event) => {
                                                                    riskItemList[index].riskFactor = event;
                                                                    setRiskItemList([...riskItemList]);
                                                                }}
                                                            />
                                                        </React.Fragment>
                                                    ),
                                                },
                                                {
                                                    dataIndex: "riskPost",
                                                    title: outletContext.intl.formatMessage({id: "risk.riskPost"}),
                                                    render: (col, record, index) => (
                                                        <React.Fragment>
                                                            <Input
                                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                allowClear
                                                                value={record.riskPost}
                                                                onChange={(event) => {
                                                                    riskItemList[index].riskPost = event;
                                                                    setRiskItemList([...riskItemList]);
                                                                }}
                                                            />
                                                        </React.Fragment>
                                                    ),
                                                },
                                                {
                                                    dataIndex: "accidentType",
                                                    title: outletContext.intl.formatMessage({id: "risk.accidentType"}),
                                                    render: (col, record, index) => (
                                                        <React.Fragment>
                                                            <Input
                                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                allowClear
                                                                value={record.accidentType}
                                                                onChange={(event) => {
                                                                    riskItemList[index].accidentType = event;
                                                                    setRiskItemList([...riskItemList]);
                                                                }}
                                                            />
                                                        </React.Fragment>
                                                    ),
                                                },
                                                {
                                                    dataIndex: "riskValue",
                                                    title: outletContext.intl.formatMessage({id: "risk.riskValue"}),
                                                    render: (col, record, index) => (
                                                        <Space
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            direction="vertical"
                                                        >
                                                            <Form.Item
                                                                style={{
                                                                    marginBottom: "0px",
                                                                }}
                                                                layout="horizontal"
                                                                label={outletContext.intl.formatMessage({id: "risk.riskValueL"})}
                                                            >
                                                                <Select
                                                                    placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                    allowClear
                                                                    value={record.riskValueL}
                                                                    onChange={(event) => {
                                                                        riskItemList[index].riskValueL = event;
                                                                        setRiskItemList([...riskItemList]);
                                                                    }}
                                                                >
                                                                    <Select.Option value={10}>10</Select.Option>
                                                                    <Select.Option value={6}>6</Select.Option>
                                                                    <Select.Option value={3}>3</Select.Option>
                                                                    <Select.Option value={1}>1</Select.Option>
                                                                    <Select.Option value={0.5}>0.5</Select.Option>
                                                                    <Select.Option value={0.2}>0.2</Select.Option>
                                                                    <Select.Option value={0.1}>0.1</Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                style={{
                                                                    marginBottom: "0px",
                                                                }}
                                                                layout="horizontal"
                                                                label={outletContext.intl.formatMessage({id: "risk.riskValueE"})}
                                                            >
                                                                <Select
                                                                    placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                    allowClear
                                                                    value={record.riskValueE}
                                                                    onChange={(event) => {
                                                                        riskItemList[index].riskValueE = event;
                                                                        setRiskItemList([...riskItemList]);
                                                                    }}
                                                                >
                                                                    <Select.Option value={10}>10</Select.Option>
                                                                    <Select.Option value={6}>6</Select.Option>
                                                                    <Select.Option value={3}>3</Select.Option>
                                                                    <Select.Option value={1}>1</Select.Option>
                                                                    <Select.Option value={0.5}>0.5</Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                style={{
                                                                    marginBottom: "0px",
                                                                }}
                                                                layout="horizontal"
                                                                label={outletContext.intl.formatMessage({id: "risk.riskValueC"})}
                                                            >
                                                                <Select
                                                                    placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                    allowClear
                                                                    value={record.riskValueC}
                                                                    onChange={(event) => {
                                                                        riskItemList[index].riskValueC = event;
                                                                        setRiskItemList([...riskItemList]);
                                                                    }}
                                                                >
                                                                    <Select.Option value={100}>100</Select.Option>
                                                                    <Select.Option value={40}>40</Select.Option>
                                                                    <Select.Option value={15}>15</Select.Option>
                                                                    <Select.Option value={7}>7</Select.Option>
                                                                    <Select.Option value={3}>3</Select.Option>
                                                                    <Select.Option value={1}>1</Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Space>
                                                    ),
                                                },
                                                {
                                                    dataIndex: "precautionaryMeasure",
                                                    title: outletContext.intl.formatMessage({id: "risk.precautionaryMeasure"}),
                                                    render: (col, record, index) => (
                                                        <React.Fragment>
                                                            <Input
                                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                allowClear
                                                                value={record.precautionaryMeasure}
                                                                onChange={(event) => {
                                                                    riskItemList[index].precautionaryMeasure = event;
                                                                    setRiskItemList([...riskItemList]);
                                                                }}
                                                            />
                                                        </React.Fragment>
                                                    ),
                                                },
                                                {
                                                    dataIndex: "controlDepartment",
                                                    title: outletContext.intl.formatMessage({id: "risk.controlDepartment"}),
                                                    render: (col, record, index) => (
                                                        <React.Fragment>
                                                            <Input
                                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                allowClear
                                                                value={record.controlDepartment}
                                                                onChange={(event) => {
                                                                    riskItemList[index].controlDepartment = event;
                                                                    setRiskItemList([...riskItemList]);
                                                                }}
                                                            />
                                                        </React.Fragment>
                                                    ),
                                                },
                                                {
                                                    dataIndex: "riskLevel",
                                                    title: outletContext.intl.formatMessage({id: "risk.riskLevel"}),
                                                    render: (col, record, index) => (
                                                        <React.Fragment>
                                                            <Select
                                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                allowClear
                                                                value={record.riskLevel}
                                                                onChange={(event) => {
                                                                    riskItemList[index].riskLevel = event;
                                                                    setRiskItemList([...riskItemList]);
                                                                }}
                                                            >
                                                                <Select.Option value={"低风险"}>低风险</Select.Option>
                                                                <Select.Option value={"一般风险"}>一般风险</Select.Option>
                                                                <Select.Option value={"较大风险"}>较大风险</Select.Option>
                                                                <Select.Option value={"重大风险"}>重大风险</Select.Option>
                                                            </Select>
                                                        </React.Fragment>
                                                    ),
                                                },
                                                {
                                                    dataIndex: "riskUser",
                                                    title: outletContext.intl.formatMessage({id: "risk.riskUser"}),
                                                    render: (col, record, index) => (
                                                        <React.Fragment>
                                                            <Select
                                                                mode="multiple"
                                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                                allowClear
                                                                value={record.userIdList}
                                                                onChange={(event) => {
                                                                    riskItemList[index].userIdList = event;
                                                                    setRiskItemList([...riskItemList]);
                                                                }}
                                                            >
                                                                {companyUserList.map((companyUser, companyUserIndex) => {
                                                                    return (
                                                                        <Select.Option key={companyUserIndex} value={companyUser.userId}>
                                                                            {companyUser.userName}
                                                                        </Select.Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </React.Fragment>
                                                    ),
                                                },
                                            ]}
                                            rowKey="itemId"
                                            data={riskItemList}
                                            childrenColumnName="childrenList"
                                            rowSelection={{
                                                type: "checkbox",
                                                columnWidth: 50,
                                                selectedRowKeys: selectedIdList,
                                                onChange: (selectedRowKeys) => {
                                                    setSelectedIdList(
                                                        Util.getSelectedIdList(selectedIdList, riskItemList, "itemId", selectedRowKeys)
                                                    );
                                                },
                                            }}
                                            scroll={{x: 50 + 2000}}
                                            border={false}
                                            pagination={false}
                                        />
                                    </div>
                                </Card>
                            ) : null}
                        </Form>
                        <div className="card_bottom"></div>
                    </div>
                </Scrollbars>
                {isLoad ? <Loading /> : null}
            </Modal>
        </React.Fragment>
    );
};

RiskDetail.propTypes = {
    visible: PropTypes.bool.isRequired,
    action: PropTypes.string.isRequired,
    riskId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Util.withRouter(RiskDetail);
